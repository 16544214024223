.level-up-dialog {
    .MuiPaper-root {
        background-color: transparent;
    }

    .MuiPaper-elevation24 {
        box-shadow: none;
    }

    .MuiDialogContent-root {
        position: relative;
        padding: 0;
        color: #fff;
        overflow: hidden;
    }
}

.level-up-dialog-title {
    position: absolute;
    top: 0px;
    left: calc(50% - 100px);
    color: #fff;
    font-size: 38px;
    text-shadow: black 1px 1px 2px;
    height: 45px;
    padding: 20px;
    background: no-repeat center/ 100% 100% url('../../resources/img/board/plank_13.png');
}

.level-up-dialog-validate-btn {
    position: absolute;
    z-index: -1;
    bottom: 0px;
    left: calc(50% - 70px);
    color: #fff;
    font-size: 24px;
    text-align: center;
    text-shadow: black 1px 1px 2px;
    height: 25px;
    padding: 15px 20px;
    background: no-repeat center/ 100% 90% url('../../resources/img/board/Plank_12_bg-reverse.png');
}

.level-up-dialog-content {
    // position: relative;
    margin-top: 45px;
    margin-bottom: 50px;
    height: 100%;
    width: 100%;
    background: no-repeat center/ 100% 100% url('../../resources/img/board/stats_background.png');

    .level-up-stat-list {
        padding: 45px 20px 25px 20px;
    }
    .level-up-stat-spacing {
        padding: 5px 0px;
    }
    .level-up-stat-row {
        display: grid;
        grid-column: 3;
        grid-template-columns: 33% 33% 1fr;
        padding: 1px 0px;
        &.selected .next-level-stat {
            position: relative;
        }
        &.selected .next-level-stat::after,
        &.selected .next-level-skill::after {
            content: '';
            position: absolute;
            font-size: 48px;
            text-align: right;
            width: 100%;
            height: 100%;
            border: 3px solid rgba(255, 255, 255, 0.5);
            z-index: 130;
        }
        &.selected .next-level-stat::after {
            top: -3px;
            right: -3px;
            border-radius: 5px;
        }
        &.selected .next-level-skill::after {
            top: -5px;
            right: -6px;
            padding: 2px;
            border-radius: 20px;
        }
        & > div {
            justify-self: center;
        }

        .stat-value-container {
            width: 50px;
            height: 50px;
        }

        .arrow-container {
            width: 35px;
            height: 60px;
            .arrow-right {
                margin-top: 3px;
                transform: scale(calc(35 / 168));
            }
        }
    }
}