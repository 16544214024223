/* Generated by http://css.spritegen.com CSS Sprite Generator */

.arrow-down, .arrow-down-hover, .arrow-up, .arrow-up-hover, .arrow-left,
.arrow-left-hover, .arrow-right, .arrow-right-hover
{
  display: inline-block;
  background: url('../../resources/img/board/arrow_sprites.png') no-repeat;
  overflow: hidden; text-indent: -9999px; text-align: left;
  transform-origin: 0 0;
  transform: scale(calc(40 / 168));
  margin-left: 5px;
}

.arrow-down { background-position: -0px -0px; width: 254px; height: 168px; }
.arrow-down:active { background-position: -0px -168px; width: 254px; height: 168px; }
.arrow-up { background-position: -0px -336px; width: 254px; height: 168px; }
.arrow-up:active { background-position: -0px -504px; width: 254px; height: 168px; }
.arrow-left { background-position: -0px -672px; width: 168px; height: 254px; }
.arrow-left:active { background-position: -0px -926px; width: 168px; height: 254px; }
.arrow-right { background-position: -0px -1180px; width: 168px; height: 254px; }
.arrow-right:active { background-position: -0px -1434px; width: 168px; height: 254px; }

.arrow-container {
  width: 40px;
  height: 70px;
}