/* Generated by http://css.spritegen.com CSS Sprite Generator */

.attaque-action-btn, .attaque-action-btn-down, .move-action-btn, .move-action-btn-down, .stuff-action-btn,
.stuff-action-btn-down {
    display: inline-block;
    background: url('../../resources/img/icon/action/actions.png') no-repeat;
    overflow: hidden;
    transform-origin: 0 0;
}

.small {
    .attaque-action-btn, .attaque-action-btn-down, .move-action-btn, .move-action-btn-down, .stuff-action-btn,
    .stuff-action-btn-down {
        transform: scale(calc(115 / 151));
    }
}

.attaque-action-btn { background-position: -0px -0px; width: 151px; height: 151px; }
.attaque-action-btn:active { background-position: -151px -0px; width: 151px; height: 151px; }
.move-action-btn { background-position: -0px -151px; width: 151px; height: 151px; }
.move-action-btn:active { background-position: -151px -151px; width: 151px; height: 151px; }
.stuff-action-btn { background-position: -0px -302px; width: 151px; height: 151px; }
.stuff-action-btn:active { background-position: -151px -302px; width: 151px; height: 151px; }

.player-actions {
    position: relative;
    width: 100%;
    height: calc(50% - 154px);
    max-width: 500px;
    margin: auto;
    .player-actions-bottom-left {
        position: absolute;
        left: 2%;
        bottom: 2%;
    }
    .player-actions-bottom-right {
        position: absolute;
        right: 2%;
        bottom: 2%;
    }
    .player-actions-top-middle {
        position: absolute;
        left: calc(50% - 57px);
        top: 1%;
    }

    .action-button {
        position: relative;
        height: 100%;
        width: 100%;
    }
}