.player-stats {
    padding: 3%;
    height: 45%;
    min-height: 330px;
    background: url('../../resources/img/board/stats_background.png') no-repeat;
    background-size: 100% 100%;

    .hero-header {
        display: block;
        height: 150px;
        .hero-container {
            position: relative;
            float: left;
            width: 100px;
            height: 150px;
            padding: 0 15px;
            z-index: 105;
            .level {
                position: absolute;
                display: flex;
                z-index: 110;
                align-items: center;
                justify-content: center;
                top: -10px;
                left: calc(50% - 24px);
                width: 48px;
                height: 48px;
                font-size: 24px;
                text-shadow: black 1px 1px 2px;
                background: no-repeat center/contain url('../../resources/img/board/flag.png');
            }
        }

        .hero-status {
            float: left;
            width: calc(100% - 150px);
        }

        .hero-name {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 24px;
            text-shadow: black 1px 1px 2px;
            height: 45px;
            text-align: center;
            background: no-repeat center/ 100% 100% url('../../resources/img/board/Plank_07.png');
        }

        .jauges-container {
            width: 100%;
        }
    }

    .competences{
        display: grid;
        grid-template-columns: 140px 180px;
        width: 320px;
        margin: auto;

        .competences-principales {
            float: left;
        }
        .competences-secondaires {
            float: right;
        }

        .competences-container {
            float: left;
            padding: 5px 0px;
            height: 45px;
            width: 75px;
        }
    }
}