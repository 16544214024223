.stat-field {
  position: relative;
  height: 46px;
  width: 83px;
  margin: auto;

  .field {
    position: relative;
    float: left;
    width: 100px;
    height: 45px;
    background: no-repeat center/ contain url("../../../resources/img/board/stat-field.png");
    .digit {
      position: absolute;
      top: 12px;
      left: 55px;
      font-size: 20px;
      text-shadow: #000000 2px 1px 2px;
    }
  }

  .logo-container {
    position: absolute;
    top: -2px;
    height: 50px;
    width: 50px;
    z-index: 110;
    background: no-repeat center/ contain url("../../../resources/img/board/stat-socle.png");

    .logo {
      position: absolute;
      top: 0;
      height: 50px;
      width: 50px;
      z-index: 120;
      &.type-cac {
        background: no-repeat center/ contain url("../../../resources/img/icon/skill/swords.png");
      }
      &.type-defense {
        background: no-repeat center/ contain url("../../../resources/img/icon/skill/defense.png");
      }
      &.type-mana {
        background: no-repeat center/ contain url("../../../resources/img/icon/skill/mana.png");
      }
      &.type-magie {
        background: no-repeat center/ contain url("../../../resources/img/icon/skill/magie.png");
      }
      &.type-tir {
        background: no-repeat center/ contain url("../../../resources/img/icon/skill/arrow.png");
      }
      &.type-ecole-1 { background: no-repeat center/ contain url("../../../resources/img/ecoles/01.png"); }
      &.type-ecole-2 { background: no-repeat center/ contain url("../../../resources/img/ecoles/02.png"); }
      &.type-ecole-3 { background: no-repeat center/ contain url("../../../resources/img/ecoles/03.png"); }
      &.type-ecole-4 { background: no-repeat center/ contain url("../../../resources/img/ecoles/04.png"); }
      &.type-ecole-5 { background: no-repeat center/ contain url("../../../resources/img/ecoles/05.png"); }
    }
  }
}


.stat-field.small {
  height: 40px;
  width: 80px;
  margin-left: 15px;
  margin-top: 5px;
  .field {
    width: 80px;
    height: 35px;
    .digit {
      top: 8px;
      left: 45px;
    }
  }
  .logo-container {
    height: 40px;
    width: 40px;
    .logo {
      height: 40px;
      width: 40px;
    }
  }
}