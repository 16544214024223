.player-compteurs {
    display: grid;
    width: 96%;
    grid-column-gap: 5px;
    grid-template-columns: 22% 22% 22% 1fr;
    grid-template-rows: 1fr;
    padding: 2% 2%;

    & > div {
        justify-self: center;
    }
}