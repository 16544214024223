@font-face {
  font-family: 'hqFont';
  src: url('../font/HEROQUESTCYRUNICORN.TTF');
}

body {
  box-sizing:  border-box !important;
  font-family: 'hqFont', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.MuiSvgIcon-root.icon {
  font-size: 3rem;
}

.loading-screen,
.main {
  position: relative;
  z-index: 0;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: repeat url('../img/board/board.png');
  background-size: 300px 200px;
}

.loading-screen img {
  display: block;
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
}

.background {
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
}

.background > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

@import "arrow-sprites";
@import "hero-sprites";
