.stat-compteur {
  display: grid;
  grid-column: 1;
  grid-row: 3;
  grid-template-columns: 70px;
  grid-template-rows: 40px 70px 40px;
  grid-row-gap: 2px;
}

.stat-value-container {
  padding: 5px;
  width: 60px;
  height: 60px;
  background: no-repeat center/100% url('../../../resources/img/board/frame.png');

  .stat-value-frame {
    display:flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    text-shadow: #000000 2px 2px 2px;
    &.skin-green {
      background: no-repeat center/100% url('../../../resources/img/board/frame_green.png');
    }
    &.skin-violet {
      background: no-repeat center/100% url('../../../resources/img/board/frame_violet.png');
    }
    &.skin-blue {
      background: no-repeat center/100% url('../../../resources/img/board/frame_blue.png');
    }
    &.skin-red {
      background: no-repeat center/100% url('../../../resources/img/board/frame_red.png');
    }
  }
}