.jauge {
  position: relative;
  width: 100%;
  height: 20px;
  border: 2px solid #B79D7B;
  border-radius: 5px;
  background-color: #5A4730;
  text-align: center;
  .digits {
    position: absolute;
    top: 1px;
    left: calc(50% - 15px);
  }
  .filled {
    height: 20px;
    border-radius: 5px;
    &.red {
      background: repeat-x left/100% 16px url("../../../resources/img/board/jauge-red.png");
    }
    &.blue {
      background: repeat-x left/100% 16px url("../../../resources/img/board/jauge-blue.png");
    }
    &.green {
      background: repeat-x left/100% 16px url("../../../resources/img/board/jauge-green.png");
    }
    &.violet {
      background: repeat-x left/100% 16px url("../../../resources/img/board/jauge-violet.png");
    }
  }
}