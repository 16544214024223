/* Generated by http://css.spritegen.com CSS Sprite Generator */

.barbare, .barbare-dead, .barbare-tired, .barbare-passif,
.elfe, .elfe-dead, .elfe-tired, .elfe-passif,
.mage, .mage-dead, .mage-tired, .mage-passif,
.nain, .nain-dead, .nain-tired, .nain-passif,
.druide, .druide-dead, .druide-tired, .druide-passif {
  display: block;
  background: url('../../resources/img/hero/heroes_sprite.png') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left;
  transform-origin: 0 0;
  transform: scale(calc(150 / 414));
  width: 257px;
  height: 414px;
  background-size: 100%;
}

.barbare { background-position: 0 0%; background-size: 100%; }
.barbare-passif { background-position: 0 6.666667%; background-size: 100%; }
.barbare-tired { background-position: 0 13.333333%; background-size: 100%; }
.barbare-dead { background-position: 0 20%; background-size: 100%; }
.elfe { background-position: 0 26.666667%; background-size: 100%; }
.elfe-passif { background-position: 0 33.333333%; background-size: 100%; }
.elfe-tired { background-position: 0 40%; background-size: 100%; }
.elfe-dead { background-position: 0 46.666667%; background-size: 100%; }
.mage { background-position: 0 53.333333%; background-size: 100%; }
.mage-passif { background-position: 0 60%; background-size: 100%; }
.mage-tired { background-position: 0 66.666667%; background-size: 100%; }
.mage-dead { background-position: 0 73.333333%; background-size: 100%; }
.nain { background-position: 0 80%; background-size: 100%; }
.nain-passif { background-position: 0 86.666667%; background-size: 100%; }
.nain-tired { background-position: 0 93.333333%; background-size: 100%; }
.nain-dead { background-position: 0 100%; background-size: 100%; }
.druide { background-position: 0 26.666667%; background-size: 100%; }
.druide-passif { background-position: 0 33.333333%; background-size: 100%; }
.druide-tired { background-position: 0 40%; background-size: 100%; }
.druide-dead { background-position: 0 46.666667%; background-size: 100%; }