.board {
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  color: white;
}

.board-header {
  display: flex;
  padding: 5% 3% 2%;
  div {
    float:right;
    width: 100%;
    text-align:right;
  }
}

.player-container {
  height: 100%;
}